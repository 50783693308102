<template>
  <div class="aside">
    <AsideMenu />
  </div>
</template>

<script>
import AsideMenu from './AsideMenu';
export default {
  name: 'Aside',
  components: { AsideMenu },
};
</script>

<style scoped>
.aside {
  background-color: rgb(0, 21, 41);
  height: 100%;
}
</style>
