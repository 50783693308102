<template>
  <div class="main">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Main',
};
</script>

<style scoped>
.main {
  height: 100%;
  background-color: RGB(245, 246, 249);
  padding: 0;
  overflow-x: hidden;
}
</style>
