<template>
  <LayoutContainer />
</template>

<script>
import LayoutContainer from '../components/dashboard/LayoutContainer';
export default {
  name: 'Dashboard',
  components: { LayoutContainer },
};
</script>
