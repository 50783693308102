<template>
  <el-container style="height: 100%">
    <el-header height="55px">
      <Header />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <Aside />
      </el-aside>
      <el-main>
        <Main />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Aside from './Aside';
import Header from './Header';
import Main from './Main';

export default {
  name: 'LayoutContainer',
  components: { Aside, Header, Main },
};
</script>
<style scoped>
.el-header,
.el-main {
  padding: 0;
}
</style>
