<template>
  <div class="menu_wrap">
    <ul class="menu_ul">
      <el-tooltip
        popper-class="tooltip_box"
        :visible-arrow="false"
        placement="right"
      >
        <div slot="content" class="tooltip_content">
          <el-row :gutter="0">
            <el-col
              :md="12"
              v-for="i in 9"
              :key="i"
              style="text-align: center; padding: 5px 0"
            >
              <div class="tooltip_btn">选项{{ i }}</div>
            </el-col>
          </el-row>
        </div>
        <li style="height: 50px">
          <div class="quick_create">
            <div style="width: 110px; display: inline-block">快速创建</div>
            <div style="width: 30px; display: inline-block">| +</div>
          </div>
        </li>
      </el-tooltip>
      <li v-for="item in items" :key="item.id">
        <router-link tag="div" active-class="active_link" :to="item.path">
          <i :class="item.icon" />
          <span>{{ item.tab }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AsideMenu',
  data() {
    return {
      // 暂且怎么简单怎么来，先不搞axios请求...
      items: [
        {
          id: 1,
          path: '/dashboard/workbench',
          tab: '仪表盘',
          icon: 'el-icon-odometer',
        },
        {
          id: 2,
          path: '/dashboard/message',
          tab: '待办事项',
          icon: 'el-icon-document',
        },
        {
          id: 3,
          path: '/dashboard/leads',
          tab: '线索',
          icon: 'el-icon-connection',
        },
        {
          id: 4,
          path: '/dashboard/customer',
          tab: '客户',
          icon: 'el-icon-s-custom',
        },
        {
          id: 5,
          path: '/dashboard/contact',
          tab: '联系人',
          icon: 'el-icon-phone',
        },
        {
          id: 6,
          path: '/dashboard/staff',
          tab: '员工',
          icon: 'el-icon-suitcase',
        },
        // {
        //   id: 6,
        //   path: "/dashboard/business",
        //   tab: "商机",
        //   icon: "el-icon-suitcase"
        // },
        {
          id: 7,
          path: '/dashboard/dept',
          tab: '部门',
          icon: 'el-icon-notebook-1',
        },
        {
          id: 8,
          path: '/dashboard/position',
          tab: '职位',
          icon: 'el-icon-money',
        },
        {
          id: 9,
          path: '/dashboard/contract',
          tab: '合同',
          icon: 'el-icon-tickets',
        },
        {
          id: 10,
          path: '/dashboard/receivable',
          tab: '回款',
          icon: 'el-icon-position',
        },
        {
          id: 11,
          path: '/dashboard/product',
          tab: '产品',
          icon: 'el-icon-goods',
        },
      ],
    };
  },
};
</script>

<style>
.menu_wrap {
  height: 100%;
  width: 100%;
}
.menu_ul {
  list-style: none;
  padding-top: 20px;
}
.menu_ul > li {
  height: 36px;
  color: grey;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}
.menu_ul > li > div {
  line-height: 36px;
  height: 36px;
  width: 160px;
  border-radius: 3px;
  font-size: 13px;
  padding-left: 12px;
  cursor: pointer;
}
.menu_ul > li > div > span {
  margin-left: 7px;
}
.quick_create {
  color: #999;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 3px;
}
.quick_create:hover {
  background-color: #2362fb;
  color: white;
}
.active_link {
  background-color: #2362fb;
  color: white;
}
.tooltip_content {
  width: 180px;
  padding: 5px;
}
.tooltip_box {
  top: 85px !important;
  background-color: white !important;
  box-shadow: 2px 2px 12px 0 rgb(0 0 0 / 10%);
}
.tooltip_btn {
  background-color: #f0f0f0;
  border-radius: 0;
  padding: 10px;
  margin: 2px 10px;
  color: black;
  cursor: pointer;
}
.tooltip_btn:hover {
  background-color: #2362fb;
  color: white;
}
</style>
